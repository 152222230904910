<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showAddNew="true"
          title="Sales Return list"
          @onClickAddNewButton="navigateToAddPage"
      />
    </div>

    <div class="my-2 px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="onClickGo"
      />

    </div>
    <div class="col-12 px-2">
      <ListTable :sales_return="salesReturnList" route_name="sales-return-edit"/>
    </div>
    <div class="px-2">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import {ref, inject, onMounted, computed} from 'vue'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/inventory/sales-return/ListTable.vue'
import handlePurchaseAndSalesReturn from "@/services/modules/purchaseSalesReturn";

const router = useRouter()
const store = useStore()
let offset = ref(20)
let salesReturnList = ref([])
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess');

const {fetchSalesReturnList} = handlePurchaseAndSalesReturn()

const companyId = computed(() => {
  return route.params.companyId
})
const startDate = computed(() => {
  return route.query.start
})
const endDate = computed(() => {
  return route.query.end
})
const page = computed(() => {
  return route.query.page
})

function navigateToAddPage() {
  router.push({name: 'sales-return-add', params: route.params, query: route.query})
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  if (!startDate.value && !endDate.value) return query
  if (startDate.value) query += '&start_date=' + startDate.value
  if (endDate.value) query += '&end_date=' + endDate.value
  if (page.value) query += '&page=' + page.value
  query += '&offset=' + offset.value
  return query
}

function onClickGo() {
  fetchSalesReturnList(getQuery())
      .then(res => {
        if (res.status) {
          salesReturnList.value = res.date.data
          setPagination(res.date)
        } else {
          salesReturnList.value = []
          store.commit("resetPagination")
        }

      })
}

function setPagination(data) {
  if (!data.data.length) return
  store.commit('setPaginationData', {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    onClickGo()
  }, 100)
}

onMounted(() => {
  onClickGo()
})

</script>
